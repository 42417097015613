import React, { Component } from "react";
import EventInfoWithIcons from "./eventInfoWithIcons";
import {
  determineTitleWithDuration,
  CapitalizeFirstLetterOfEveryWord,
  IsEmptyObject,
  doesTitleIncludeVariableBlocks,
} from "../services/commonUsefulFunctions";
import classNames from "classnames";
import HostDetails from "./hostDetails";

class AvailabilityLinkEventInfo extends Component {
  render() {
    const {
      additionalClassName,
      alignItems,
      selectedTimeZone,
      shouldShowTimeZoneDropdown,
      onSelectTimeZone,
      info,
      isGroupVoteSpreadSheet,
      children,
    } = this.props;

    const { isMobile } = info;

    return (
      <div
        className="availability-event-info"
        style={
          isMobile
            ? {
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: alignItems ?? "flex-start",
                padding: 15,
              }
            : {
                paddingTop: 25,
                paddingBottom: isGroupVoteSpreadSheet ? 10 : 25,
                paddingLeft: 30,
              }
        }
      >
        {this.renderProfileSection()}

        {(info.duration || info.isGroupVote) && (
          <div
            className={classNames("availability-link-title")}
            style={{ fontSize: 20 }}
          >
            {this.determineLargeTitle()}
          </div>
        )}

        <EventInfoWithIcons
          info={info}
          additionalClassName={classNames(additionalClassName)}
          containerClassName={isMobile ? "mb-5 mt-5" : "mt-5 mb-2"}
          addMarginRightToEachSection={true}
          selectedTimeZone={selectedTimeZone}
          shouldShowTimeZoneDropdown={shouldShowTimeZoneDropdown}
          onSelectTimeZone={onSelectTimeZone}
        />
      </div>
    );
  }

  renderProfileSection() {
    const { children, isGroupVoteSpreadSheet } = this.props;
    if (isGroupVoteSpreadSheet && children) {
      return (
        <div className="flex justify-between">
          {this.renderHostDetails()}

          {/* inline icon */}
          {children ? <div className="mr-5">{children}</div> : null}
        </div>
      );
    }
    return this.renderHostDetails();
  }

  renderHostDetails() {
    const { children, isGroupVoteSpreadSheet, info } = this.props;
    if (children && isGroupVoteSpreadSheet) {
      return (
        <div>
          <HostDetails
            hostDisplayName={this.determineName()}
            profilePhotoSrc={info.profile_photo_url}
            socialLinks={info.social_links}
          />
        </div>
      );
    }

    return (
      <HostDetails
        hostDisplayName={this.determineName()}
        profilePhotoSrc={info.profile_photo_url}
        socialLinks={info.social_links}
      />
    );
  }

  determineName() {
    if (IsEmptyObject(this.props.info.user)) {
      return null;
    }

    if (this.props.info.invitee_full_name) {
      return `${CapitalizeFirstLetterOfEveryWord(
        this.props.info.invitee_full_name
      )} and ${this.getSelfName()}`;
    }

    return this.getSelfName();
  }

  getSelfName() {
    if (this.isSchedulingForExec()) {
      return (
        CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name) ||
        this.props.info.user.email ||
        ""
      );
    }

    return (
      CapitalizeFirstLetterOfEveryWord(this.props.info.sender_full_name) ||
      CapitalizeFirstLetterOfEveryWord(
        this.props.info.master_account?.full_name
      ) ||
      CapitalizeFirstLetterOfEveryWord(this.props.info.user?.full_name) ||
      this.props.info.user.email
    );
  }

  isSchedulingForExec() {
    return this.props.info.is_scheduling_for_exec;
  }

  determineLargeTitle() {
    const {
      personal_link,
      title,
      invitee_full_name: inviteeName,
      inputName,
      duration,
      custom_questions,
    } = this.props.info;
    const { companyName } = this.props;

    if (personal_link?.name) {
      return personal_link.name;
    }

    // if we wanted to show title
    // const updatedTitle = replaceVariableBlocksWithValue({
    //   title,
    //   inviteeName: inviteeName || inputName,
    //   companyName,
    //   questions: custom_questions
    // });

    if (title && !doesTitleIncludeVariableBlocks({ title })) {
      return title;
    }

    return determineTitleWithDuration(duration);
  }
}

export default AvailabilityLinkEventInfo;
