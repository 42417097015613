import { SECOND_IN_MS } from "../services/globalVariables";

export const GOOGLE_PLACES_SCRIPT_SRC =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyAtHmHWIzVLD3sKO_hh-2zYP6xOcjGpBWE&libraries=places&loading=async";
export const GOOGLE_PLACES_ID = "googlePlaces";
const GOOGLE_API_KEY = "AIzaSyAuxEFGIveDj5qUTJZkDs-l87Z3MwV4EOc";

// https://developers.google.com/maps/documentation/geocoding/requests-geocoding#StatusCodes
export const GOOGLE_GEOCODING_RESPONSE_STATUS = {
  OK: "OK",
  ZERO_RESULTS: "ZERO_RESULTS",
  OVER_DAILY_LIMIT: "OVER_DAILY_LIMIT",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  INVALID_REQUEST: "INVALID_REQUEST",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
};

// https://developers.google.com/maps/documentation/timezone/requests-timezone#TimeZoneStatus
export const GOOGLE_TIME_ZONE_RESPONSE_STATUS = {
  OK: "OK",
  INVALID_REQUEST: "INVALID_REQUEST",
  OVER_DAILY_LIMIT: "OVER_DAILY_LIMIT",
  OVER_QUERY_LIMIT: "OVER_QUERY_LIMIT",
  REQUEST_DENIED: "REQUEST_DENIED",
  UNKNOWN_ERROR: "UNKNOWN_ERROR",
  ZERO_RESULTS: "ZERO_RESULTS",
};

export function getGoogleMapsAutoComplete() {
  if (!window?.google) {
    return;
  }
  return new window.google.maps.places.AutocompleteService();
}

export function getGeoCodeFromAddress(address) {
  if (!address) {
    return;
  }
  const encodedLocation = encodeURIComponent(address);
  const apiCall = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedLocation}&key=${GOOGLE_API_KEY}`;
  return fetch(apiCall);
}

export function getTimeZoneFromLocation({ lat, lng }) {
  const loc = `${lat}, ${lng}`; // Tokyo expressed as lat,lng tuple

  const targetDate = new Date(); // Current date/time of user computer
  const timestamp =
    targetDate.getTime() / SECOND_IN_MS + targetDate.getTimezoneOffset() * 60; // Current UTC date/time expressed as seconds since midnight, January 1, 1970 UTC

  const apiCall =
    "https://maps.googleapis.com/maps/api/timezone/json?location=" +
    loc +
    "&timestamp=" +
    timestamp +
    "&key=" +
    GOOGLE_API_KEY;

  return fetch(apiCall);
}
