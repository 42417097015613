import md5 from "md5";
import moment from "moment";
import { ALL_VALID_GMT_OFFSET_TIMEZONES, IANNA_TIME_ZONES, POPULAR_TIME_ZONES, POPULAR_TIME_ZONES_INDEX, TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX, TIME_ZONE_SEARCH_QUERY_INDEX, getValidFloatingPointGMTTimeZone } from "./timeZone";
import { ALL_BOOKING_VARIABLES, BACKEND_HANGOUT, INVITEE_COMPANY_NAME, microsoftTeamsIconURL, OUTLOOK_CONFERENCING, SKYPE_FOR_BUSINESS_ICON, SKYPE_FOR_BUSINESS_LABEL, SKYPE_FOR_CONSUMER_LABEL, SKYPE_ICON_URL, TEAMS_FOR_BUSINESS_LABEL, UTC_TIME_ZONE, ZOOM_LOGO_URL } from "./globalVariables";
import _ from "underscore";
import * as Sentry from "@sentry/browser";
import PhoneNumber from "awesome-phonenumber";
import {
  differenceInMinutes,
  parseISO,
  set,
  subDays,
  startOfMinute,
  addMinutes,
  formatISO,
  subMinutes,
  addDays,
  format,
  isBefore,
  isSameMinute,
  startOfDay,
  isAfter,
  isSameDay,
  endOfDay,
  parseJSON,
  isValid,
  differenceInHours,
  subHours,
} from "date-fns";
import { constructRequestURL } from "./api";
import Fetcher from "./fetcher";
import { v4 as uuidv4 } from 'uuid';
import { getCurrentTrip, getTripTimeZone } from "../lib/tripsFunctions";
import { GOOGLE_PLACES_ID } from "../lib/googleFunctions";

const PROJECT_NAME = "availability";
export const KEYCODE_ENTER = 13;
export const KEYCODE_TAB = 9;
export const KEYCODE_DELETE = 8;
export const KEYCODE_COMMA = 188;
export const KEYCODE_SPACE = 32;

let _staticTimeZoneList = {};

let _isSafari;
let _guessedTimeZone;

export function isElectron() {
  let userAgent = navigator.userAgent.toLowerCase();

  return userAgent.indexOf(" electron/") > -1;
}

export function IsEmptyObject(obj) {
  return !obj || Object.keys(obj).length === 0;
}

export function StringHasNumber(myString) {
  return /\d/.test(myString);
}

export function constructQueryParams(params) {
  let keysArray = Object.keys(params);

  if (keysArray.length === 0) return "";

  let query = keysArray
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  return query;
}

export function createSlotsForSelection(timeSlots, duration) {
  // timeSlots come in the time zone provided
  // end_time come in utc

  // Algorithm
  // 1. convert slots to local time zone
  // 2. find closest 30min/hour interval and start slashing duration from each slot
  // 3. add slot to day index

  // Convert slots to local timezone
  if (!timeSlots || timeSlots.length === 0) {
    return {};
  }

  let convertedTimeZoneSlots = [];

  timeSlots.forEach((s) => {
    let { start_time, end_time } = s;
    let updatedStart = moment(start_time).local();
    let updatedEnd = moment(end_time).local();

    convertedTimeZoneSlots = convertedTimeZoneSlots.concat({
      start: updatedStart,
      end: updatedEnd,
    });
  });

  // Create day index and add duration slots to each day
  let dayIndex = {};

  // iterate through each slot and add to dayIndex
  convertedTimeZoneSlots.forEach((s) => {
    const slotStart = s.start.startOf("minute");
    const slotEnd = s.end.startOf("minute");

    const slotStartRounded15 = RoundToClosestMinute(slotStart, 15, "", true);
    const slotStartRounded30 = RoundToClosestMinute(slotStart, 30, "", true);
    let slotStartRounded = slotStartRounded30; // default to 30
    if (shouldRoundToNearest15(duration)) {
      // Already set to round to 15
      slotStartRounded = slotStartRounded15;
    }
    // else if (slotEnd.diff(slotStartRounded30, "minutes") >= duration) {
    //   slotStartRounded = slotStartRounded30;
    // }

    if (
      slotEnd.diff(slotStart, "minutes") < duration ||
      slotEnd.diff(slotStartRounded, "minutes") < duration
    ) {
      // Do nothing, can't escape out of forEach loop
    } else {
      // Slot are bigger than duration -> start slicing duration off each one
      dayIndex = sliceDurationAndReturnSlot(
        duration,
        slotStartRounded,
        slotEnd,
        dayIndex
      );
    }
  });

  return dayIndex;
}

export function mergeBusySlots(busySlots) {
  // incase slots do not come back as chunks that are grouped together
  // chunks: 8:30 - 11:30 and 7 - 10 -> chunking together would be 7- 11:30
  if (!busySlots || busySlots.length === 0) {
    return [];
  } else if (busySlots.length === 1) {
    return busySlots;
  }

  const formattedSlots = busySlots.map((s) => {
    // Outlook returns UTC which we parse with parseJSON
    return parseFreeBusyTimeSlot(s);
  });
  const sorted = formattedSlots.sort((a, b) => SortEvents(a, b, "start"));

  // Algorithm:
  // 1. keep going through the array until the start time of the next event  is after the end of the last event
  // if start time of the next event is not after the end time of the last event -> merge event together
  // if start time of the next event is after, put the last merged event into the new array
  // if last element in array -> put current slot in

  let mergedSlots = [];
  let currentSlot = sorted[0];
  sorted.forEach((s, index) => {
    if (index === 0) {
      // skip since we already set it as the initial value
      return;
    }

    if (isAfterMinute(s.start, currentSlot.end)) {
      // if after, put the curentSlot into mergedSlots and set current slot to s.start
      mergedSlots = mergedSlots.concat(currentSlot);
      currentSlot = s;
    } else if (
      isSameOrBeforeMinute(s.start, currentSlot.end) &&
      isAfterMinute(s.end, currentSlot.end)
    ) {
      // if s. start is before the end of current slot -> merge the slot together
      currentSlot.end = s.end;
    }

    if (index === sorted.length - 1) {
      // if last slot, add the curent slot in
      mergedSlots = mergedSlots.concat(currentSlot);
      return;
    }
  });

  return mergedSlots.map((s) => {
    return { start: s.start.toISOString(), end: s.end.toISOString() };
  });
}

function parseFreeBusyTimeSlot(timeSlot) {
  if (timeSlot.is_outlook) {
    return { start: parseJSON(timeSlot.start), end: parseJSON(timeSlot.end) }
  }
  
  return { start: parseISO(timeSlot.start), end: parseISO(timeSlot.end) }
}

export function createFreeSlotsBasedOnBusySlots(freeStart, freeEnd, busySlots) {
  //All time slots come in as utc

  let start = moment(freeStart).startOf("minute");
  let end = moment(freeEnd).startOf("minute");

  // console.log('start out', start.format('LLLL'));
  // console.log('end out', end.format('LLLL'));

  let currentTime = moment();
  if (
    currentTime.isAfter(start, "minute") &&
    currentTime.isBefore(end, "minute")
  ) {
    start = currentTime;
  } else if (currentTime.isSameOrAfter(end)) {
    return [];
  }

  if (!busySlots || busySlots.length === 0) {
    //Default case where there's no busy slots
    return [{ start_time: start.toISOString(), end_time: end.toISOString() }];
  }

  // Scenerios: top = busy, bottom = free
  let freeSlots = [];
  let shouldSkip = false;

  const isDebugModeOn = false;

  busySlots.forEach((s, index) => {
    // console.log('start', start.format('LLLL'));
    // console.log('end', end.format('LLLL'));
    // console.log('index', index);
    // console.log('shouldSkip', shouldSkip);
    // console.log('start', start.format('LTS'));
    // console.log('end', end.format('LTS'));
    // console.log('s.start', moment(s.start).format('LTS'));
    // console.log('s.end', moment(s.end).format('LTS'));

    let busyStart = moment(s.start).startOf("minute");
    let busyEnd = moment(s.end).startOf("minute");

    // console.log('busyStart', busyStart.format('LLLL'));
    // console.log('busyEnd', busyEnd.format('LLLL'));

    const parsed = parseFreeBusyTimeSlot(s); //TODO: delete

    if (shouldSkip) {
      isDebugModeOn && console.log("here0", parsed);
      //  Do nothing
    } else if (
      busyStart.isSameOrBefore(start, "minute") &&
      busyEnd.isSameOrAfter(end, "minute")
    ) {
      isDebugModeOn && console.log("here1", parsed);
      // |------|       |------------|       |--------------------|       |-------------|
      // |------| or          |------|    or     |---------|         or   |----|
      // Skip this time slot altogether
      shouldSkip = true;
    } else if (
      busyStart.isSameOrBefore(start, "minute") &&
      busyEnd.isBefore(end, "minute") &&
      busyEnd.isAfter(start, "minute")
    ) {
      isDebugModeOn && console.log("here2", parsed);
      // |-------|            |---------|
      //       |------|   or  |--------------|
      // have to use isAfter otherwise case where busy ends right as free start would fail
      start = busyEnd;
    } else if (
      busyEnd.isSameOrAfter(end, "minute") &&
      busyStart.isAfter(start, "minute") &&
      busyStart.isBefore(end, "minute")
    ) {
      isDebugModeOn && console.log("here3", parsed);
      //           |---------|              |-----|
      //     |----------|        or    |----------|

      end = busyStart;
    } else if (
      busyEnd.isBefore(end, "minute") &&
      busyStart.isAfter(start, "minute")
    ) {
      isDebugModeOn && console.log("here5", parsed);
      //         |---|
      //   |-----------------|
      let slot = {
        start_time: start.toISOString(),
        end_time: busyStart.toISOString(),
      };
      freeSlots = freeSlots.concat(slot);

      start = busyEnd;
    } else {
      isDebugModeOn && console.log("here6", parsed);
      // nothing
    }
  });

  if (!shouldSkip) {
    let slot = { start_time: start.toISOString(), end_time: end.toISOString() };
    freeSlots = freeSlots.concat(slot);
  }

  return freeSlots;
}

export function sortTimeSlots(dayTimeObject) {
  let updatedDaySlot = {};

  Object.keys(dayTimeObject).forEach((k) => {
    let list = dayTimeObject[k].sort((a, b) =>
      SortEvents(a, b, "start", false)
    );

    updatedDaySlot[k] = list;
  });

  return updatedDaySlot;
}

export function SortArrayByDate(a, b) {
  let eventA = moment(a);
  let eventB = moment(b);

  if (eventA.isBefore(eventB, "day")) {
    return -1;
  }
  if (eventB.isBefore(eventA, "day")) {
    return 1;
  }

  // names must be equal
  return 0;
}

export function IsEmailValid(email) {
  if (!email) {
    return false;
  }
  // https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression/201378#201378
  // const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  // https://emailregex.com
  const emailRegex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const isValidEmail = email.match(emailRegex);
  return isValidEmail;
}

export function OpenLink(url) {
  if (isElectron()) {
    const { shell } = window.require("electron");

    shell.openExternal(url);
  } else {
    window.open(url, "_blank");
  }
}

export function AddAbbrevationToTimeZone(timeZone, returnNull = false) {
  if (!timeZone) {
    return returnNull ? null : "";
  }
  if (!isValidTimeZone(timeZone)) {
    return timeZone;
  }
  return (
    "(" +
    createAbbreviationForTimeZone(timeZone, new Date()) +
    ") " +
    timeZone.replace(/_/g, " ")
  );
}

export function isValidTimeZone(tz) {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: tz });
    return true;
  } catch (ex) {
    return false;
  }
}

export function isValidJSDate(date) {
  if (!date) {
    return false;
  }
  return (typeof date !== "string" || !date instanceof String) && isValid(date);
}

export function safeGuardTimeZones(option) {
  // used to check if time zone is valid and if not then try to convert to safe time zone
  if (option?.timeZone) {
    const inputTimeZone = option.timeZone.toLowerCase();
    if (inputTimeZone.includes("gmt+") || inputTimeZone.includes("gmt-")) {
      // prevent bugs like "GMT-05:00"
      const splitArray = inputTimeZone.split(/[+-]+/); // split based on + -
      // list of valid time zones: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      // for this case, need to create something like Etc/GMT-1
      let defaultGMTString = "Etc/GMT";

      let plusMinusHour = splitArray[1] || "";
      if (plusMinusHour.includes(":")) {
        plusMinusHour = plusMinusHour.substring(0, plusMinusHour.indexOf(":")); // remove : and everything after it ("5:00 -> 5")
      }

      // https://www.tutorialspoint.com/javascript-regex-to-remove-leading-zeroes-from-numbers
      plusMinusHour = plusMinusHour.replace(/\D|^0+/g, "").trim(); //remove leading 0's

      let timeZone = `${defaultGMTString}${
        inputTimeZone.includes("-") ? "-" : "+"
      }${plusMinusHour || "0"}`;
      return { ...option, timeZone };
    } else if (inputTimeZone.includes("gmt00:00")) {
      let timeZone = "Etc/GMT0";
      return { ...option, timeZone };
    }
  }
  return option;
}


export function createAbbreviationForTimeZone(timeZone) {
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleTimeString
  // breaks for Europe (CEST)
  const inputTimeZone = isValidTimeZone(timeZone) ? timeZone : guessTimeZone();
  const inputDate = new Date();
  try {
    const timeZoneAbbreviation = inputDate
      .toLocaleString(
        "en",
        safeGuardTimeZones({ timeZone: inputTimeZone, timeZoneName: "short" })
      )
      .split(" ")
      .pop();

    const isStringNumerical = StringHasNumber(timeZoneAbbreviation);

    if (isStringNumerical && TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone]) {
      const isDayLightSavings = isDSTObserved(
        timeZone,
        inputDate
      );

      return isDayLightSavings
        ? TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone].dayLightSavings
        : TIME_ZONE_ABBREVIATION_OVERRIDE_INDEX[timeZone].standard;
    }

    return timeZoneAbbreviation;
  } catch (error) {
    handleError(error);
    return "";
  }
}

export function isDSTObserved(inputTimeZone = null, inputDate = null) {
  let timeZone = inputTimeZone || guessTimeZone();
  let date = inputDate || new Date();
  // https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
  return getTimeZoneOffSet(date, timeZone) < stdTimezoneOffset(date, timeZone);
}

function getTimeZoneOffSet(date, timeZone) {
  let converted = convertToTimeZone(date, { timeZone });
  let utcTimeZone = convertToTimeZone(date, { timeZone: UTC_TIME_ZONE });

  return -1 * differenceInMinutes(converted, utcTimeZone);
}

export function parseUTCDate(dateInput) {
  const isString = (typeof dateInput === 'string');
  return isString ? new Date(parseISO(dateInput)) : dateInput;
}

export function convertToTimeZone(date, options) {
  if (!isValidTimeZone(options?.timeZone) || options.timeZone === guessTimeZone()) {
    return parseUTCDate(date);
  }
  // options = {timeZone: tz};
  // https://stackoverflow.com/questions/10087819/convert-date-to-another-timezone-in-javascript
  try {
    const convertedTimeInTimeZone = new Date(
      parseUTCDate(date).toLocaleString(
        "en-US",
        safeGuardTimeZones(options)
      )
    );
    if (isNaN(convertedTimeInTimeZone.getTime())) {
      return new Date();
    }

    if (options.timeZone === "Pacific/Fiji") {
      // fiji government recently (2022) stopped supporting day light savings but browsers haven't fixed this yet
      // below checks for dls without calling convertToTimeZone which causes endless loop
      const currentTime = new Date();
      const fijiTime = new Date(currentTime.toLocaleString("en-US", { timeZone: 'Pacific/Fiji' }));
      const utcTime = new Date(currentTime.toLocaleString("en-US", { timeZone: UTC_TIME_ZONE }));
      const diff = differenceInHours(fijiTime, utcTime);
      if (diff !== 12) {
        return subHours(convertedTimeInTimeZone, 1);
      }
    }

    return convertedTimeInTimeZone;
  } catch (error) {
    handleError(error);
    return new Date();
  }
}

export function stdTimezoneOffset(date, timeZone) {
  let jan = new Date(date.getFullYear(), 0, 1);
  let jul = new Date(date.getFullYear(), 6, 1);

  return Math.max(
    getTimeZoneOffSet(jan, timeZone),
    getTimeZoneOffSet(jul, timeZone)
  );
}

export function RoundToClosestMinute(
  time,
  interval,
  format = "LT",
  skipFormatting = false
) {
  let start = moment(time);
  let remainder = interval - (start.minute() % interval);

  remainder = remainder === interval ? 0 : remainder;

  let dateTime;

  if (!skipFormatting) {
    dateTime = moment(start).clone().add(remainder, "minutes").format(format);
  } else {
    dateTime = moment(start).clone().add(remainder, "minutes");
  }

  return dateTime;
}

export function nearestMinutes(interval, someMoment) {
  const roundedMinutes =
    Math.round(someMoment.clone().minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestPastMinutes(interval, someMoment) {
  const roundedMinutes = Math.floor(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function nearestFutureMinutes(interval, someMoment) {
  const roundedMinutes = Math.ceil(someMoment.minute() / interval) * interval;
  return someMoment.clone().minute(roundedMinutes).second(0);
}

export function RoundDownToClosestMinute(time, interval) {
  // if 11:05 -> 11:00
  return nearestPastMinutes(interval, moment(time));
}

export function sliceDurationAndReturnSlot(duration, start, end, dayIndex) {
  let updatedDayIndex = _.clone(dayIndex || {});

  if (end.diff(start, "minutes") < duration) {
    // Sanity check
    return updatedDayIndex;
  }

  let sliceTime = shouldRoundToNearest15(duration) ? 15 : 30;

  // StartClone is mutable
  let startClone = start.clone();

  while (end.diff(startClone, "minutes") >= duration) {
    startClone = RoundToClosestMinute(startClone, 15, "", true);
    let startClone30 = RoundToClosestMinute(startClone, 30, "", true);

    if (shouldRoundToNearest15(duration)) {
      // startClone already rounded up to 15
    } else if (end.diff(startClone30, "minutes") >= duration) {
      startClone = startClone30;
    }

    let startTime = startClone.format("LLL");
    let startTimeMoment = startClone.clone();

    let startDay = startClone.format("dddd LL");

    let endTime = startClone.clone().add(duration, "minute").format("LLL");

    startClone.add(sliceTime, "minute").format("LLL");

    if (end.diff(startTimeMoment, "minutes") < duration) {
      // Not enough space -> ignore
    } else if (Object.keys(updatedDayIndex).includes(startDay)) {
      updatedDayIndex[startDay] = updatedDayIndex[startDay].concat({
        start: startTime,
        end: endTime,
      });
    } else {
      updatedDayIndex[startDay] = [{ start: startTime, end: endTime }];
    }
  }

  return updatedDayIndex;
}

export function SortEvents(a, b, customKey = null, reverse = false) {
  let eventA = moment(a[customKey || "eventStart"]);
  let eventB = moment(b[customKey || "eventStart"]);

  if (eventA.isBefore(eventB)) {
    return reverse ? 1 : -1;
  }
  if (eventB.isBefore(eventA)) {
    return reverse ? -1 : 1;
  }

  // names must be equal
  return 0;
}

export function handleError(error, context) {
  if (!error) {
    return;
  }

  const NO_TOKEN = "no token";
  let token = NO_TOKEN;
  let email = "no_email";
  let userCalendarId = "no_user_calendar_id";

  if (!IsEmptyObject(context)) {
    if (context.token) {
      token = context.token;
    }

    if (context.user) {
      if (context.user.email) {
        email = context.user.email;
      }

      if (context.user.user_calendar_id) {
        userCalendarId = context.user.user_calendar_id;
      }
    }
  }

  let environment =
    process.env.REACT_APP_STAGE || process.env.REACT_APP_CLIENT_ENV;

  if (environment === "dev") {
    console.log("dev error: ", error, context);
  } else {
    Sentry.captureException(new Error(error || "Availability project error"), {
      tags: {
        project: PROJECT_NAME,
        token,
        email,
        userCalendarId,
      },
      extra: !IsEmptyObject(context) ? context : { error: "no extra data" },
    });
    let contextAsString = "no context"
    try {
      if (!IsEmptyObject(context)) {
        contextAsString = JSON.stringify(context)
      }
      trackError({
        category: `Availability project error ${error ?? "no error"}`, 
        errorMessage: contextAsString
      });
    } catch (error) {
      // do nothing
      if (environment === "dev" || !environment) {
        console.log("dev error in catch: ", error, context);
      }
    }
  }
}

export function formatRFCPhoneNumber(phoneNumber, regionCode) {
  let pn = new PhoneNumber(phoneNumber, regionCode);

  let formattedPhoneNumber = pn.getNumber("rfc3966");

  return formattedPhoneNumber;
}

export function isMac() {
  return navigator.platform.toLowerCase().includes("mac");
}

export function generateBookableSlotsFromObj({
  slots,
  timeZone,
  daysForward,
  shouldFormatIntoUTC = true,
  bufferBefore,
  bufferAfter,
  upcomingTrips
}) {
  let freeSlots = [];

  const daysArray = [...Array(daysForward + 1).keys()];

  if (IsEmptyObject(slots)) {
    return [];
  }

  daysArray.forEach((d) => {
    const date = addDays(new Date(), d);
    const dayOfWeek = format(date, "EEEE");
    const dayOfWeekTimes = slots[dayOfWeek];
    const tripForDate = getCurrentTrip(upcomingTrips, date);
    const timeZoneToUse = tripForDate && getTripTimeZone(tripForDate) 
      ? getTripTimeZone(tripForDate)
      : timeZone;

    if (!dayOfWeekTimes) {
      return;
    }

    dayOfWeekTimes.forEach((t) => {
      const start_utc = createTimeFromDayOfWeek({
        date,
        timeZone: timeZoneToUse,
        hour: t.start.hour,
        minute: t.start.minute,
        shouldFormatIntoUTC,
        bufferBefore,
      });

      let end_utc = createTimeFromDayOfWeek({
        date,
        timeZone: timeZoneToUse,
        hour: t.end.hour,
        minute: t.end.minute,
        shouldFormatIntoUTC,
        bufferAfter,
      });

      if (moment(start_utc).isAfter(moment(end_utc), "minute")) {
        end_utc = moment(end_utc).add(1, "day").format();
      }

      freeSlots = freeSlots.concat({
        start_time: start_utc,
        end_time: end_utc,
        index: freeSlots.length,
      });
    });
  });

  return freeSlots;
}

export function createTimeFromDayOfWeek({
  date,
  timeZone,
  hour,
  minute = 0,
  shouldFormatIntoUTC = true,
  bufferBefore,
  bufferAfter,
}) {
  // 1 is Monday
  // 0 is current sunday, 7 is next sunday
  if (timeZone) {
    const formattedTime = startOfMinute(
      getTimeInAnchorTimeZone(
        set(date, { hours: hour, minutes: minute || 0 }),
        timeZone
      )
    );

    if (shouldFormatIntoUTC) {
      if (bufferBefore) {
        return subMinutes(formattedTime, bufferBefore).toISOString();
      } else if (bufferAfter) {
        return addMinutes(formattedTime, bufferAfter).toISOString();
      }

      return formattedTime.toISOString();
    } else {
      if (bufferBefore) {
        return formatISO(subMinutes(formattedTime, bufferBefore));
      } else if (bufferAfter) {
        return formatISO(addMinutes(formattedTime, bufferAfter));
      }

      return formatISO(formattedTime);
    }
  } else {
    const formattedTime = startOfMinute(
      set(date, { hours: hour, minutes: minute || 0 })
    );

    if (shouldFormatIntoUTC) {
      if (bufferBefore) {
        return subMinutes(formattedTime, bufferBefore).toISOString();
      } else if (bufferAfter) {
        return addMinutes(formattedTime, bufferAfter).toISOString();
      }

      return formattedTime.toISOString();
    } else {
      if (bufferBefore) {
        return formatISO(subMinutes(formattedTime, bufferBefore));
      } else if (bufferAfter) {
        return formatISO(addMinutes(formattedTime, bufferAfter));
      }

      return formatISO(formattedTime);
    }
  }
}

export function getAllBusySlots({
  response,
  roundUpInterval,
  bufferBefore,
  bufferAfter,
  bufferFromNow
}) {
  if (!response || !response.free_busy) {
    return [];
  }

  let busySlots = [
    createBusyBlockerForCurrentTime(roundUpInterval, bufferFromNow),
  ];

  const bufferParam = { bufferBefore, bufferAfter };

  response.free_busy.forEach((info) => {
    if (!info.busy_slots) {
      return;
    }

    info.busy_slots.forEach((r) => {
      let slot = {
        start: addBufferToEvents({
          time: r.start, 
          isAllDay: false, 
          shouldAddBuffer: true, 
          isBufferBefore: true, 
          param: bufferParam, 
          isOutlook: r.is_outlook
        }),
        end: addBufferToEvents({
          time: r.end, 
          isAllDay: false, 
          shouldAddBuffer: true, 
          isBufferBefore: false, 
          param: bufferParam, 
          isOutlook: r.is_outlook
        }),
      };

      busySlots = busySlots.concat(slot);
    });
  });

  busySlots = mergeBusySlots(busySlots);

  return busySlots;
}

export function createBusyBlockerForCurrentTime(
  roundUpInterval,
  bufferFromNow
) {
  // add 2 hours from now
  const bufferWithSafety = bufferFromNow ?? 3;

  const currentStart = startOfMinute(
    RoundToClosestMinuteJSDate(
      subMinutes(new Date(), bufferWithSafety),
      roundUpInterval
    )
  );
  const currentEnd = startOfMinute(
    RoundToClosestMinuteJSDate(
      addMinutes(new Date(), bufferWithSafety),
      roundUpInterval
    )
  );

  return { start: currentStart.toISOString(), end: currentEnd.toISOString() };
}

export function RoundToClosestMinuteJSDate(jsDate, interval) {
  let remainder = interval - (jsDate.getMinutes() % interval);

  remainder = remainder === interval ? 0 : remainder;

  return addMinutes(jsDate, remainder);
}

// Create free slots from busy slots
export function addBufferToEvents({
  time,
  isAllDay,
  shouldAddBuffer,
  isBufferBefore,
  param,
  isOutlook = false
}) {
  let { bufferBefore, bufferAfter } = param;
  const parsedTime = isOutlook ? parseJSON(time) : parseISO(time);

  if (!shouldAddBuffer) {
    // bug with utcToZonedTime
    // https://github.com/marnusw/date-fns-tz/issues/75
    return parsedTime.toISOString();
  }

  if (isBufferBefore) {
    return subMinutes(parsedTime, bufferBefore).toISOString();
  } else {
    return addMinutes(parsedTime, bufferAfter).toISOString();
  }
}

export function generateRandomId(length = 32) {
  // 32 is the default length of event ids;
  let alphabet = "abcdefghijklmnopqrstuv".split("");
  let numbers = "1234567890".split("");

  let options = alphabet.concat(numbers);

  let result = "";

  let i;
  for (i = 0; i < length; i++) {
    let item = options[Math.floor(Math.random() * options.length)];

    result = result + item;
  }

  return result;
}

export function generateAvailabilityToken(token) {
  const tokenWithTime = `${token}${new Date().toISOString()}`;

  return md5(tokenWithTime).slice(0, 24).toLowerCase();
}

export function generateFreeSlotsFromBusySlots({
  busySlots = [],
  slots,
  daysForward,
  durationMinutes,
  timeZone,
  upcomingTrips
}) {
  let freeSlots = [];
  let roundUpInterval = shouldRoundToNearest15(durationMinutes) ? 15 : 30;
  let roundDownInterval = 15;

  // generateBookableSlotsFromObj -> array as utc
  const dayOfWeeKSlots = generateBookableSlotsFromObj({
    slots,
    timeZone,
    daysForward,
    shouldFormatIntoUTC: true,
    upcomingTrips
  });

  dayOfWeeKSlots.forEach((s) => {
    let freeSlot = createFreeSlotsBasedOnBusySlots(
      s.start_time,
      s.end_time,
      busySlots
    );

    if (freeSlot.length === 0) {
      return;
    }

    freeSlot.forEach((f) => {
      let { start_time, end_time } = f;
      let roundedUpStartTime = RoundToClosestMinute(
        start_time,
        roundUpInterval,
        null,
        true
      ).startOf("minute");
      let roundedUpEndTime = RoundDownToClosestMinute(
        end_time,
        roundDownInterval
      ).startOf("minute");

      if (
        roundedUpEndTime.diff(roundedUpStartTime, "minutes") >= durationMinutes
      ) {
        freeSlots = freeSlots.concat({
          start_time: roundedUpStartTime.format(),
          end_time: roundedUpEndTime.format(),
        });
      }
    });
  });

  return freeSlots;
}

export function getGoogleEventId(event) {
  if (IsEmptyObject(event)) {
    return null;
  }

  if (event.gcal_event_id) {
    return event.gcal_event_id;
  } else if (event.raw_json && event.raw_json.id) {
    return event.raw_json.id;
  }

  return null;
}

export function getResponseStartTime(response) {
  return response?.event?.start_time_utc ?? response?.event?.raw_json?.start?.dateTime;
}

export function isEventStartBeforeNow(response) {
  return moment(getResponseStartTime(response)).isBefore(moment(), "minute");
}

export function guessTimeZone() {
  if (_guessedTimeZone) {
    return _guessedTimeZone;
  }

  const guessedTimeZone = getDeviceTimeZone();
  _guessedTimeZone = guessedTimeZone;
  return guessedTimeZone;
}

function isFloatingPoint(num) {
  return num !== Math.floor(num);
}

// something ike 6.75, 6.25, 6.5
function roundToNearestQuarter(num) {
  return Math.round(num * 4) / 4;
}

function getBackupDetectedTimeZone() {
  try {
    const offsetMinutes = new Date().getTimezoneOffset();
    const offsetHours = offsetMinutes / 60;
    if (isFloatingPoint(offsetHours)) {
      const roundedOffsetHours = roundToNearestQuarter(offsetHours);
      if (getValidFloatingPointGMTTimeZone(roundedOffsetHours)) {
        return getValidFloatingPointGMTTimeZone(roundedOffsetHours);
      }
    }

    switch (offsetHours) {
      case 0:
        return "UTC";
      case 13:
        return "Pacific/Tongatapu";
      case 14:
        return "Pacific/Kiritimati";
      default:
        const timeZone = `Etc/GMT${offsetHours >= 0 ? '-' : '+'}${Math.abs(offsetHours)}`;
        if (!ALL_VALID_GMT_OFFSET_TIMEZONES.includes(timeZone)) {
          return null;
        }
        return timeZone;
    }
  } catch {
    return POPULAR_TIME_ZONES_INDEX.LOS_ANGELES
  }
}

function getDeviceTimeZone() {
  const detectedTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (detectedTimeZone) {
    return detectedTimeZone;
  }

  const backupDetectedTimeZone = getBackupDetectedTimeZone();
  if (backupDetectedTimeZone) {
    return backupDetectedTimeZone;
  }

  return POPULAR_TIME_ZONES_INDEX.LOS_ANGELES; // this way it doesn't return undefined which would break redux
}

export function reformatMinDuration(mins) {
  if (!mins) {
    return { hours: 0, minutes: 0 };
  }

  let hours = Math.floor(mins / 60);
  let minutes = mins % 60;

  return { hours, minutes };
}

export function pluralize(number, string) {
  if (number > 1) {
    return (string = string + "s");
  } else {
    return string;
  }
}

export function determineTitleWithDuration(durationMinute) {
  let durationInfo = reformatMinDuration(durationMinute);

  if (durationInfo.hours > 0) {
    let title = `${durationInfo.hours} Hour`;

    if (durationInfo.minutes > 0) {
      return title + ` and ${durationInfo.minutes} Minute Meeting`;
    }

    return title + " Meeting";
  } else {
    return `${durationInfo.minutes} Minute Meeting`;
  }
}

export function determineEventDuration(event) {
  if (
    !(event && event.raw_json && event.raw_json.start && event.raw_json.end)
  ) {
    return null;
  }

  let start = event.raw_json.start.dateTime || event.raw_json.start.date;
  let end = event.raw_json.end.dateTime || event.raw_json.end.date;

  return moment(end).diff(moment(start), "minutes");
}

export function expandedDateAndTimeString(result) {
  // January 9, 2020, 12:15am – January 10, 2020, 3:15am
  const startDate = moment(result.eventStart).format("MMM D, YYYY");
  const startTime = moment(result.eventStart).format("h:mma");

  const endDate = moment(result.eventEnd).format("MMM D, YYYY");
  const endTime = moment(result.eventEnd).format("h:mma");

  return startDate === endDate
    ? `${startDate}, ${startTime} - ${endTime}`
    : `${startDate}, ${startTime} - ${endDate}, ${endTime}`;
}

export function CreateJSDate(date) {
  let parts = date.split("-");

  // Please pay attention to the month (parts[1]); JavaScript counts months from 0:
  // January - 0, February - 1, etc.
  let mydate = new Date(parts[0], parts[1] - 1, parts[2], 1);

  return mydate;
}

export function formatEvent(event) {
  let currentTimeZone = guessTimeZone();

  return {
    ...event,
    eventStart: event.start.date
      ? parseISO(event.start.date)
      : convertToTimeZone(event.start.dateTime, { timeZone: currentTimeZone }),
    eventEnd: event.end.date
      ? formatEndDateJsDate(event.end.date)
      : convertToTimeZone(event.end.dateTime, { timeZone: currentTimeZone }),
  };
}

export function formatEndDateJsDate(endDateString) {
  // end date string is from google event, usually in the form of event.end.date
  return set(subDays(parseISO(endDateString), 1), { hours: 1 });
}

export function checkIfBookableSlotsAreValid(slots) {
  // array with start_time and end_time
  if (!slots || slots.length === 0) {
    return false;
  }

  let isValid = false;

  slots.forEach((s) => {
    if (
      (!isValid && moment(s.start_time).isAfter(moment(), "minute")) ||
      moment(s.end_time).isAfter(moment(), "minute")
    ) {
      isValid = true;
    }
  });

  return isValid;
}

export function CapitalizeFirstLetterOfEveryWord(word) {
  if (!word) {
    return "";
  }

  return word.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
}

export function CapitalizeFirstletter(string) {
  if (!string) {
    return "";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sendBreadcrumbToSentry({ category, message, data, level }) {
  let environment = process.env.REACT_APP_CLIENT_ENV;

  if (!environment || environment === "dev") {
    // uncomment before merge
    console.log(`Breadcrumb: ${category} ${message}`);
  } else {
    Sentry.addBreadcrumb({
      category,
      message,
      data,
      level: level || Sentry.Severity.Info,
    });
  }
}

export function breadcrumbData(data) {
  return acceptableBreadcrumbData(data) ? data : null;
}

function acceptableBreadcrumbData(data) {
  if (Array.isArray(data)) {
    return data.every(
      (datum) =>
        // Basic values (strings, numbers)
        typeof datum !== "object" ||
        // Empty Array
        (Array.isArray(datum) && datum.length === 0) ||
        // Empty Object
        (datum && typeof datum === "object" && Object.keys(datum).length === 0)
    );
  } else if (data !== "object") {
    return acceptableBreadcrumbData(Object.values(data));
  } else {
    return true;
  }
}

function sendMessageToSentry(message1, message2) {
  let environment = process.env.REACT_APP_CLIENT_ENV;

  if (!environment || environment === "dev") {
    console.error(
      `Availability_project Log message error: ${message1} ${message2}`
    );
  } else {
    Sentry.captureMessage(`Availability_project ${message1}: ${message2}`);
  }
}

export function sentryLogging(context) {
  if (IsEmptyObject(context)) {
    sendMessageToSentry("error", "no context");
    return;
  }

  let { error, token } = context;

  let environment = process.env.REACT_APP_CLIENT_ENV;

  if (!environment || environment === "dev") {
    console.error(`Availability_project Log message error: ${error}`);
  } else {
    // https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
    Sentry.captureException(new Error(`Availability_project ${error}`), {
      tags: {
        project: PROJECT_NAME,
        token,
      },
      extra: context,
    });
  }
}

export function createError(error = "") {
  throw `Availability_project ${error}`;
}

export function getTimeInAnchorTimeZone(
  jsDate,
  eventTimeZone,
  anchorTimeZone = null
) {
  // if anchor time zone is pdt and jsDate is 3pm and eventTimeZone is edt
  // this is not to translate 3pm est -> pdt
  // rather, this is setting 3pm in pdt
  // need to do this since js date does not allow you to set time zone but only does local time zone calculations
  let minutesDifference = minDifferentBetweenTimeZones(
    anchorTimeZone || guessTimeZone(),
    eventTimeZone || guessTimeZone()
  );

  return eventTimeZone !== (anchorTimeZone || guessTimeZone())
    ? addMinutes(jsDate, minutesDifference)
    : jsDate;
}

export function minDifferentBetweenTimeZones(
  firstTimeZone = guessTimeZone(),
  secondTimeZone = guessTimeZone(),
  inputTime = null
) {
  let time = startOfMinute(inputTime || new Date());

  let firstTimeZoneTime = convertToTimeZone(time, { timeZone: firstTimeZone });
  let secondTimeZoneTime = convertToTimeZone(time, {
    timeZone: secondTimeZone,
  });

  return differenceInMinutes(firstTimeZoneTime, secondTimeZoneTime);
}

function getZoomHostEmail(zoomMeeting) {
  return zoomMeeting?.host_email;
}

function getZoomJoinURL(zoomMeeting) {
  return zoomMeeting?.join_url;
}

function createZoomNoteFromUniqueZoom(zoomMeeting) {
  const hostEmail = getZoomHostEmail(zoomMeeting);
  const joinURL = getZoomJoinURL(zoomMeeting);
  if (!hostEmail || !joinURL) {
    return null;
  }
  return `"Meeting host: <a href="mailto:${hostEmail}" target="_blank">${hostEmail}</a><br /><br />Join Zoom Meeting: <br /><a href="${joinURL}" target="_blank">${joinURL}</a>"`;
}

export function createUniqueZoom(zoomMeeting) {
  let videoConferencingOption = {
    entryPointType: "video",
    uri: zoomMeeting.join_url,
    label: zoomMeeting.join_url,
    meetingCode: zoomMeeting.id,
  };

  const conferenceSolution = {
    key: {
      type: "addOn",
    },
    name: "Zoom Meeting",
    iconUri: ZOOM_LOGO_URL,
  };

  let entryPoints = [videoConferencingOption];

  let { phoneInfo } = getZoomMeetingDetails(zoomMeeting);

  let phoneOption;
  if (phoneInfo) {
    let phoneNumber = formatRFCPhoneNumber(phoneInfo.number, phoneInfo.country);
    phoneOption = {
      entryPointType: "phone",
      label: phoneNumber,
      regionCode: phoneInfo.country,
      uri: `${phoneNumber},,${zoomMeeting.id}#`,
    };
    entryPoints = entryPoints.concat(phoneOption);
  }

  let zoomConference = {
    entryPoints,
    conferenceSolution: conferenceSolution,
    notes: createZoomNoteFromUniqueZoom(zoomMeeting),
    id: zoomMeeting.id,
  };

  return zoomConference;
}

function getZoomMeetingDetails(zoomMeeting) {
  if (IsEmptyObject(zoomMeeting)) {
    return { phoneInfo: null };
  }

  let phoneInfo;
  if (
    zoomMeeting &&
    zoomMeeting.settings &&
    zoomMeeting.settings.global_dial_in_numbers &&
    zoomMeeting.settings.global_dial_in_numbers.length > 0
  ) {
    phoneInfo = zoomMeeting.settings.global_dial_in_numbers[0];
  }

  return { phoneInfo };
}

export function formatPhoneNumberHumanReadable(number, regionCode) {
  // create (415)816-3363
  return new PhoneNumber(number, regionCode).getNumber("national");
}

export function getPhoneNumberString(phoneNumber, regionCode) {
  // '+46707123456'
  let pn = new PhoneNumber(phoneNumber, regionCode);

  return pn.getNumber();
}

export function isBeforeMinute(JSDateA, JSDateB) {
  return isBefore(startOfMinute(JSDateA), startOfMinute(JSDateB));
}

export function isSameOrBeforeMinute(JSDateA, JSDateB) {
  let timeA = startOfMinute(JSDateA);
  let timeB = startOfMinute(JSDateB);

  return isSameMinute(timeA, timeB) || isBefore(timeA, timeB);
}

export function isSameOrAfterDay(JSDateA, JSDateB) {
  let timeA = startOfDay(JSDateA);
  let timeB = startOfDay(JSDateB);

  return isSameDay(timeA, timeB) || isAfter(timeA, timeB);
}

export function isSameOrAfterMinute(JSDateA, JSDateB) {
  let timeA = startOfMinute(JSDateA);
  let timeB = startOfMinute(JSDateB);

  return isSameMinute(timeA, timeB) || isAfter(timeA, timeB);
}

export function isAfterMinute(JSDateA, JSDateB) {
  return isAfter(startOfMinute(JSDateA), startOfMinute(JSDateB));
}

export function isBeforeDay(JSDateA, JSDateB) {
  return isBefore(startOfDay(JSDateA), startOfDay(JSDateB));
}

export function isSameOrBeforeDay(JSDateA, JSDateB) {
  return (
    isSameDay(JSDateA, JSDateB) ||
    isBefore(startOfDay(JSDateA), startOfDay(JSDateB))
  );
}

export function isAfterDay(JSDateA, JSDateB) {
  return isAfter(startOfDay(JSDateA), startOfDay(JSDateB));
}

export function updateToStartOfNextDayIfLastSlot(slotEnd) {
  // Pass back JS date
  let timeEnd = slotEnd;

  if (isSameMinute(slotEnd, endOfDay(slotEnd))) {
    let startOfNextDay = startOfDay(addDays(timeEnd, 1));
    timeEnd = startOfNextDay;
  }

  return timeEnd;
}

export function determineRBCEventEndWithEventStart(eventStart, eventEnd) {
  let startEndDiff = differenceInMinutes(eventEnd, eventStart);

  let rbcEventEnd = eventEnd;

  if (startEndDiff < 15) {
    rbcEventEnd = addMinutes(eventStart, 15);
  }

  return rbcEventEnd;
}

export function generateLargeRandomNumber(size = null) {
  if (size && isInt(size)) {
    return Math.floor(Math.random() * Math.pow(10, size));
  }

  return Math.floor(Math.random() * 100000000);
}

export function isInt(value) {
  return !isNaN(value) && !isNaN(parseInt(value, 10));
}

export function hasStopEventPropagation(e) {
  if (e && typeof e.stopPropagation === "function") {
    e.stopPropagation();
  }
  stopNativeEventPropagation(e);
}

export function stopNativeEventPropagation(e) {
  if (e?.nativeEvent?.stopImmediatePropagation) {
    // https://stackoverflow.com/questions/24415631/reactjs-syntheticevent-stoppropagation-only-works-with-react-events
    e.nativeEvent.stopImmediatePropagation();
  }
}

export function isMobileHorizontally() {
  if (!window || !window.innerWidth) {
    return false;
  }

  return window?.innerWidth <= 600;
}

export function sortEventsJSDate(a, b, reverse = false) {
  if (isBeforeMinute(a.eventStart, b.eventStart)) {
    return reverse ? 1 : -1;
  } else if (isBeforeMinute(b.eventStart, a.eventStart)) {
    // if do not have this condition, breaks on firefox
    return reverse ? -1 : 1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isAfterMinute(a.eventEnd, b.eventEnd)
  ) {
    return reverse ? 1 : -1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isAfterMinute(b.eventEnd, a.eventEnd)
  ) {
    return reverse ? -1 : 1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isSameMinute(a.eventEnd, b.eventEnd) &&
    a.uniqueEtag > b.uniqueEtag
  ) {
    return reverse ? 1 : -1;
  } else if (
    isSameMinute(a.eventStart, b.eventStart) &&
    isSameMinute(a.eventEnd, b.eventEnd) &&
    b.uniqueEtag > a.uniqueEtag
  ) {
    return reverse ? -1 : 1;
  }

  return 0;
}

export function hasEventPreventDefault(e) {
  if (e && typeof e.preventDefault === "function") {
    e.preventDefault();
  }
}

export function shouldReduceTextSize(event, minutes = 15) {
  if (!event) {
    return false;
  }

  if (event.displayAsAllDay) {
    return false;
  }

  return (
    differenceInMinutes(
      event.end || event.eventEnd,
      event.start || event.eventStart
    ) <= minutes
  );
}

export function getQueryParam() {
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  return new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
}

export function getNameAndEmailFromQueryParam() {
  const params = getQueryParam();
  const email = params?.email;
  const name = params?.name;
  return { email, name };
}

export function getMaestroPersonalOnboardingLinkToken() {
  const randNumber = Math.random() // Generates a float between 0 and 1
  if (randNumber < 0.8) {
    // weighted to alex 80% fo the time
    return "77814c29f8702de13f5a5f3e" // Alex
  }

  return "f0fca50c7474732002773026"; // Dylan
}

export function getRegularOnboardingToken() {
  const randomNumber = generateLargeRandomNumber(5);
  const modedResult = randomNumber % 10;

  if (modedResult <= 6) {
    // sophie gets 70% of the result
    return "2afcb2c987892faf5e497b50" // sophie
  } else {
    return "c29q5WaMbaC4zQZ7" // dylan
    
  }
}

export function getSapphireOnboardingToken() {
  const randomNumber = generateLargeRandomNumber(5);
  const isEven = (randomNumber % 2) === 0;

  if (isEven) {
    return "7oCQ1TGkWQTGrJn3" // dylan
  } else {
    return "gWYDGaqKGWnejxiw" // sophie
  }
}

export function isV2() {
  return true; // change to true if we're using v2
}

export function getSlotsPath() {
  return isV2() ? "slots" : "availabilities";
}

export function isOutlookConferencingOption(conferencing) {
	return !!OUTLOOK_CONFERENCING[conferencing];
}

export function getOutlookConferencingIconURL(conferencing) {
	if (OUTLOOK_CONFERENCING.teamsForBusiness === conferencing) {
		return microsoftTeamsIconURL;
	} else if (OUTLOOK_CONFERENCING.skypeForBusiness === conferencing) {
		return SKYPE_FOR_BUSINESS_ICON;
	} else if (OUTLOOK_CONFERENCING.skypeForConsumer === conferencing) {
		return SKYPE_ICON_URL;
	}

	return SKYPE_ICON_URL;
}

export function convertOutlookConferencingToHumanReadable(conferencing) {
	switch (conferencing) {
		case OUTLOOK_CONFERENCING.teamsForBusiness:
			return TEAMS_FOR_BUSINESS_LABEL;
		case OUTLOOK_CONFERENCING.skypeForBusiness:
			return SKYPE_FOR_BUSINESS_LABEL;
		case OUTLOOK_CONFERENCING.skypeForConsumer:
			return SKYPE_FOR_CONSUMER_LABEL;
		default:
			return conferencing
	}
}

export function getVimcalURLWithAttribution() {
  const getType = () => {
    const path = window.location.pathname ?? "";

    if (path.includes("/p/")) {
      return "pl" // personal link
    } else if (path.includes("/t/")) {
      return "s" // slots
    } else if (path.includes("/g/")) {
      return "g" // group vote
    } else {
      return "booking" // general booking link
    }
  }

  return `https://vimcal.com/?a=${getType()}`;
}

export function isTestEnvironment() {
  return (
    !process.env.REACT_APP_CLIENT_ENV ||
    process.env.REACT_APP_CLIENT_ENV !== "production" 
  );
}

export function trackError({ category, errorMessage }) {
  if (isTestEnvironment()) {
    return;
  }

  const NOW = new Date();
  const data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    category,
    errorMessage,
    user_token: undefined,
    timestamp: NOW.toISOString(),
    version: "availability",
    client: "availability",
  };

  const path = "metrics/e";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: { "Accept-Encoding": "gzip" },
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
      handleError(error);
    });
}

export function trackEvent({
  category, 
  action, 
  label,
}) {
  if (isTestEnvironment()) {
    return;
  }
  
  const NOW = new Date();
  const data = {
    // Rails treats 'action' as the name of the route action, so we can't use it directly
    event_action: action,
    category,
    label,
    timestamp: NOW.toISOString(),
    client: "availability",
    url: null,
    version: "availability",
    user_token: null
  };

  const path = "metrics";
  const url = constructRequestURL(path);

  const payloadData = {
    headers: { "Accept-Encoding": "gzip" },
    body: JSON.stringify(data),
  };

  Fetcher.post(url, payloadData, false)
    .then(_.noop)
    .catch((error) => {
    });
}

export function shouldRoundToNearest15(duration) {
  if (!duration) {
    return false;
  }

  // since 30 is divisible by 15, we're going to round to 15 if it's a 30 min event
  return duration % 15 === 0 && duration % 30 !== 0;
}

export function removeDuplicatesFromArray(array) {
  return !array.length ? [] : [...new Set(array)];
}

function titleHasCompanyName(title) {
  return title?.toLowerCase().includes(ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME.toLowerCase());
}

function titleHasInviteeNameBlock(title) {
  return title?.toLowerCase().includes(ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK.toLowerCase());
}

export function doesTitleIncludeVariableBlocks({title}) {
  return titleHasInviteeNameBlock(title)
    || titleHasCompanyName(title);
}

export function shouldReplaceCompanyNameVariable({title, companyName}) {
  return companyName && doesTitleIncludeVariableBlocks({title});
}

export function isCompanyQuestion(question) {
  return question?.description === "Company";
}

// TODO: follow example in replaceInviteeNameInTitle
export function replaceCompanyNameInTitle({title, companyName}) {
  if (!title) {
    return "";
  }
  if (!companyName) {
    // should not get in here
    return title;
  }
  const trimmedCompanyName = companyName.trim();
  const companyRegExp = new RegExp(ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME, "ig");
  const companyRegExpAdditionalBrackets = new RegExp(`{{{{${ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME}}}}}`, "ig"); 
  return title
    .replace(companyRegExpAdditionalBrackets, CapitalizeFirstLetterOfEveryWord(trimmedCompanyName))
    .replace(companyRegExp, CapitalizeFirstLetterOfEveryWord(trimmedCompanyName)).trim();
}

export function shouldReplaceInviteeNameInTitle({title, inviteeName}) {
  return inviteeName && doesTitleIncludeVariableBlocks({title});
}

function removeBookingLinkBlockBrackets(variable) {
  return variable
    ?.replaceAll("{{", "")
    ?.replaceAll("}}", "") ?? "";
}

export function replaceInviteeNameInTitle({title, inviteeName}) {
  if (!title) {
    return "";
  }
  if (!inviteeName) {
    // should not get in here
    return title;
  }
  const trimmedName = inviteeName.trim();
  const nameRegExp = new RegExp(ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK, "ig");
  const nameRegExpAdditionalBrackets = new RegExp(`{{{{${removeBookingLinkBlockBrackets(ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK)}}}}}`, "ig"); 
  return title
    .replace(nameRegExpAdditionalBrackets, CapitalizeFirstLetterOfEveryWord(trimmedName))
    .replace(nameRegExp, CapitalizeFirstLetterOfEveryWord(trimmedName)).trim()
}

export function isSafari() {
  if (isNullOrUndefined(_isSafari)) {
    const userAgent = navigator.userAgent;
    const isBrowserSafari = /Safari/.test(userAgent) && !/Chrome/.test(userAgent);
    _isSafari = isBrowserSafari;
    return isBrowserSafari;
  }

  return _isSafari;
}

export function isNullOrUndefined(element) {
  return element === null || element === undefined;
}

export function createUUID(length) {
  if (!length) {
    return uuidv4();
  }

  return uuidv4().slice(0, length);
}

export function getTitle({title, customQuestions}) {
  // if (titleHasCompanyName(title)
  //   && !customQuestionsHasCompany(customQuestions)
  // ) {
  //   return title.replace(ALL_BOOKING_VARIABLES.INVITEE_COMPANY_NAME, ALL_BOOKING_VARIABLES.INVITEE_NAME_BLOCK);
  // }
  return title;
}

export function customQuestionsHasCompany(questions) {
  return questions?.find((question) => isCompanyQuestion(question));
}

export function getCompanyQuestionIndex(question) {
  return question?.findIndex((q) => isCompanyQuestion(q));
}

export function replaceVariableBlocksWithValue({
  title,
  inviteeName,
  companyName,
  questions
}) {
  if (!title) {
    return null;
  }
  let updatedTitle = title;
  if (shouldReplaceCompanyNameVariable({
    title: updatedTitle, 
    companyName
  })
  ) {
    updatedTitle = replaceCompanyNameInTitle({
      title: updatedTitle,
      companyName
    });
  } 
  // else if (!customQuestionsHasCompany(questions) && shouldReplaceCompanyNameVariable({
  //   title: updatedTitle, 
  //   companyName: inviteeName
  // })) {
  //   // if company name is not provided, use invitee name
  //   updatedTitle = replaceCompanyNameInTitle({
  //     title: updatedTitle,
  //     companyName: inviteeName
  //   });
  // }

  if (shouldReplaceInviteeNameInTitle({
    title: updatedTitle,
    inviteeName
  })) {
    updatedTitle = replaceInviteeNameInTitle({
      title: updatedTitle,
      inviteeName
    });
  }
  return updatedTitle;
}

export function isEmptyRichText(str) {
  if (!str) {
    return false;
  }
  const tempDiv = document.createElement("div");
  if (!tempDiv) {
    return false;
  }
  tempDiv.innerHTML = str;
  const textContent = tempDiv.textContent || tempDiv.innerText || "";
  if (!textContent.trim) {
    return false;
  }
  return textContent.trim() === "";
}

export function isTypeOfNumber(value) {
  return typeof value === "number";
}

export function isEmptyArray(array) {
  return !array || array.length === 0;
}

export const DEFAULT_NAME_QUESTION = {
  "type": "singleLine",
  "required": true,
  "description": "Name"
}
export const DEFAULT_EMAIL_QUESTION = {
  "type": "singleLine",
  "required": true,
  "description": "Email"
};
export const DEFAULT_COMPANY_NAME_QUESTION = {
  type: "singleLine", 
  required: true, 
  description: "Company"
};


export function getCustomQuestions({questions, title}) {
  const questionsContainCompany = questions?.some(q => q?.description === DEFAULT_COMPANY_NAME_QUESTION.description);
  if (title?.includes(INVITEE_COMPANY_NAME) && !questionsContainCompany) {
    // does not include company but has company in title
    if (isEmptyArray(questions)) {
      return [
        DEFAULT_NAME_QUESTION,
        DEFAULT_EMAIL_QUESTION,
        DEFAULT_COMPANY_NAME_QUESTION
      ]
    }
    return [].concat(questions).concat(DEFAULT_COMPANY_NAME_QUESTION);
  }
  return questions ?? [];
}

export function openLinkOnSamePage(url) {
  window.open(url, "_self");
}

export function isIOS() {
  return getOS() === "iOS";
}

export function getOS() {
  // https://dev.to/vaibhavkhulbe/get-os-details-from-the-webpage-in-javascript-b07
  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "MacOS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (!os && /Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

const PROVIDER = {
  GOOGLE: "google",
  OUTLOOK: "outlook",
};

export function isGoogleUser(user) {
  // default to google if user is not defined
  return user?.provider === PROVIDER.GOOGLE;
}

export function isOutlookUser(user) {
  return user?.provider === PROVIDER.OUTLOOK;
}

export function isOutlookConferencing(conferencing) {
  return Object.values(OUTLOOK_CONFERENCING).includes(conferencing);
}

export function isGoogleConferencing(conferencing) {
  return conferencing === BACKEND_HANGOUT;
}

export function isGoogleProvider(provider) {
  return provider === PROVIDER.GOOGLE;
}

export function isOutlookProvider(provider) {
  return provider === PROVIDER.OUTLOOK;
}

export function getConferencing({user, provider, conferencing}) {
  if ((isOutlookProvider(provider) || isOutlookUser(user)) && isGoogleConferencing(conferencing)) {
    return null; // we don't know which one to default to here
  }
  if ((isGoogleProvider(provider) || isGoogleUser(user)) && isOutlookConferencing(conferencing)) {
    return BACKEND_HANGOUT; // default to google
  }
  return conferencing;
}

export function CreateTimeZoneList(currentTz) {
  if (!!_staticTimeZoneList[format(new Date(), "P")]) {
    return _staticTimeZoneList[format(new Date(), "P")];
  }

  let currentTimeZone = IsEmptyObject(currentTz) ? guessTimeZone() : currentTz;

  let defaultList = IANNA_TIME_ZONES;

  let popularTimeZone = POPULAR_TIME_ZONES.filter(
    (timeZone) => timeZone !== currentTimeZone
  );
  popularTimeZone.unshift(currentTimeZone);

  let defaultListWithoutPopularTimeZones = defaultList.filter(
    (timeZone) => !popularTimeZone.includes(timeZone)
  );

  let popularTimeZonesObject = popularTimeZone.map((timeZone) => {
    return { value: timeZone, label: AddAbbrevationToTimeZone(timeZone) };
  });

  let defaultTimeZoneObject = defaultListWithoutPopularTimeZones.map(
    (timeZone) => {
      return { value: timeZone, label: AddAbbrevationToTimeZone(timeZone) };
    }
  );

  let allTimeZones = popularTimeZonesObject.concat(defaultTimeZoneObject);

  let TIME_ZONE_SEARCH_QUERY_INDEX_KEYS = Object.keys(
    TIME_ZONE_SEARCH_QUERY_INDEX
  );

  let addAdditionalSearchQueries = [];
  allTimeZones.forEach((t) => {
    if (TIME_ZONE_SEARCH_QUERY_INDEX_KEYS.includes(t.value)) {
      const updatedObject = {
        ...t,
        searchQueries: TIME_ZONE_SEARCH_QUERY_INDEX[t.value],
      };

      addAdditionalSearchQueries =
        addAdditionalSearchQueries.concat(updatedObject);
    } else {
      addAdditionalSearchQueries = addAdditionalSearchQueries.concat(t);
    }
  });

  _staticTimeZoneList = {}; // wipe out earlier entries
  _staticTimeZoneList[format(new Date(), "P")] = addAdditionalSearchQueries;

  return addAdditionalSearchQueries;
}
export function doesGooglePlacesScriptExist() {
  return !!document.getElementById(GOOGLE_PLACES_ID);
}

export function lowerCaseAndTrimString(str) {
  if (!str) {
    return "";
  }

  return str.toLowerCase().trim();
}

export function isSameEmail(email1, email2) {
  if (!email1 || !email2) {
    return false;
  }
  return lowerCaseAndTrimString(email1) === lowerCaseAndTrimString(email2);
}
