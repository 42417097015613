import React, { Component } from "react";
import { Calendar, Clock, Globe, MapPin, Video } from "react-feather";
import {
  BACKEND_HANGOUT,
  BACKEND_NO_CONFERENCING,
  BACKEND_PHONE,
  BACKEND_WHATS_APP,
  BACKEND_ZOOM,
  HANGOUT_LOGO_URL,
  PHONE_MEETING_ICON,
  ZOOM_LOGO_URL,
  WHATS_APP_ICON,
  BACKEND_CUSTOM_CONFERENCING,
} from "../services/globalVariables";
import {
  AddAbbrevationToTimeZone,
  CreateTimeZoneList,
  convertOutlookConferencingToHumanReadable,
  createAbbreviationForTimeZone,
  getOutlookConferencingIconURL,
  guessTimeZone,
  handleError,
  isEmptyArray,
  isOutlookConferencingOption,
} from "../services/commonUsefulFunctions";
import classNames from "classnames";
import moment from "moment";
import CustomSelect from "./customSelect";
import { customMenuStyle } from "../lib/reactSelectFunctions";
import {
  TIME_ZONE_SEARCH_QUERY_INDEX,
  countryTimeZones,
  getAllTimeZoneForGMTOffset,
  getMatchingMultipleTimeZoneKey,
  getMatchingSingleTimeZoneCountry,
  getMatchingSingleTimeZoneCountryResult,
  getMatchingTimeZoneForMultipleCountryTZs,
  getOffSetFromGMTSearchString,
  isPartOfPopularTimeZone,
  stringContainsGMTAndNumber,
  timeZonesForCountriesWithMultipleTimeZone,
} from "../services/timeZone";
import _ from "underscore";
import { GOOGLE_GEOCODING_RESPONSE_STATUS, GOOGLE_TIME_ZONE_RESPONSE_STATUS, getGeoCodeFromAddress, getGoogleMapsAutoComplete, getTimeZoneFromLocation } from "../lib/googleFunctions";

const DEFAULT_FONT_SIZE = "font-size-12";
const ICON_SIZE = 16;

class EventInfoWithIcons extends Component {
  constructor(props) {
    super(props);
    this._searchLocationTimer = null;

    this.state = {
      timeZoneOptions: CreateTimeZoneList(this.props.selectedTimeZone || guessTimeZone()),
    };

    this.onInputChange = this.onInputChange.bind(this);
    this.filterOption = this.filterOption.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setUpLocationAutoComplete();
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearTimeout(this._searchLocationTimer);
  }

  render() {
    const {
      additionalClassName,
      containerClassName,
      shouldShowTimeZoneDropdown,
    } = this.props;

    const defaultMarginTop = "mt-1.5";
    const MARGIN_RIGHT = this.props.addMarginRightToEachSection ? "mr-2" : "";

    return (
      <div
        className={classNames("width-100-percent", containerClassName ?? "")}
      >
        {this.props.info.onClickedConfirmTime &&
        this.props.info.selectedTime ? (
          <div
            className={classNames(
              "display-flex-flex-direction-row align-items-center",
              MARGIN_RIGHT,
              defaultMarginTop,
              DEFAULT_FONT_SIZE,
              additionalClassName ?? ""
            )}
          >
            <Calendar size={ICON_SIZE} className="mr-2.5" />

            {moment(this.props.info.selectedTime.start).format("dddd ll")}
          </div>
        ) : null}
        {this.renderTimeOrDuration({ defaultMarginTop })}
        {this.props.info.location && (
          <div
            className={classNames(
              "display-flex-flex-direction-row align-items-center",
              MARGIN_RIGHT,
              defaultMarginTop,
              DEFAULT_FONT_SIZE,
              additionalClassName ?? ""
            )}
          >
            <div className="margin-right-10">
              <MapPin size={ICON_SIZE} />
            </div>

            {this.props.info.location || "Home"}
          </div>
        )}

        {this.props.info.conferencing &&
          this.props.info.conferencing !== BACKEND_NO_CONFERENCING && (
            <div
              className={classNames(
                "display-flex-flex-direction-row align-items-center",
                MARGIN_RIGHT,
                defaultMarginTop,
                DEFAULT_FONT_SIZE,
                additionalClassName ?? ""
              )}
            >
              <div className="margin-right-10 padding-top-3">
                {this.renderConferencingIcon()}
              </div>

              {this.determineConferencingText()}
            </div>
          )}

        <div
          className={classNames(
            "display-flex-flex-direction-row",
            MARGIN_RIGHT,
            defaultMarginTop,
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            shouldShowTimeZoneDropdown ? "items-center" : ""
          )}
        >
          <Globe size={ICON_SIZE} className="mr-2.5 flex" />

          {this.renderTimeZone()}
        </div>
      </div>
    );
  }

  renderTimeZone() {
    const { selectedTimeZone, shouldShowTimeZoneDropdown, onSelectTimeZone } =
      this.props;
    if (!shouldShowTimeZoneDropdown || !onSelectTimeZone) {
      return (
        <div>
          {AddAbbrevationToTimeZone(selectedTimeZone ?? guessTimeZone())}
        </div>
      );
    }
    const timeZone = selectedTimeZone ?? guessTimeZone();
    return (
      <div>
        <CustomSelect
          value={{ label: AddAbbrevationToTimeZone(timeZone), value: timeZone }}
          onChange={(option) => {
            const {
              isAddOn,
              value,
            } = option;
            if (isAddOn) {
              this.fetchGeoCode(value);
              return;
            }
            onSelectTimeZone(value);
          }}
          options={this.state.timeZoneOptions}
          fontSize={"12px"}
          controllerWidth={224}
          menuListStyle={customMenuStyle({ width: 224 })}
          menuListMaxHeight={200}
          filterOption={this.filterOption}
          onInputChange={this.onInputChange}
        />
      </div>
    );
  }

  filterOption(option, inputValue) {
    const { label, value, isAddOn } = option;
    const query = inputValue.toLowerCase();

    let additionalSearchQueries = "";
    if (TIME_ZONE_SEARCH_QUERY_INDEX[value]) {
      additionalSearchQueries = TIME_ZONE_SEARCH_QUERY_INDEX[value];
    }

    if (stringContainsGMTAndNumber(inputValue)) {
      const offset = getOffSetFromGMTSearchString(inputValue);
      if (inputValue.includes("+")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt+${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      } else if (inputValue.includes("-")) {
        const timeZone = getAllTimeZoneForGMTOffset(`gmt-${offset}`);
        if (timeZone) {
          return timeZone.includes(value);
        }
      }
    }

    return (
      isAddOn ||
      label?.toLowerCase().indexOf(query) >= 0 ||
      value?.toLowerCase().indexOf(query) >= 0 ||
      additionalSearchQueries?.indexOf(query) >= 0
    );
  }

  renderTimeOrDuration({ defaultMarginTop }) {
    const { additionalClassName } = this.props;
    if (this.props.info.onClickedConfirmTime && this.props.info.selectedTime) {
      return (
        <div
          className={classNames(
            "display-flex-flex-direction-row",
            "align-items-center",
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            defaultMarginTop ?? ""
          )}
        >
          <Clock size={ICON_SIZE} className="mr-2.5" />
          {moment(this.props.info.selectedTime.start).format("h:mm A")}
        </div>
      );
    }

    if (this.props.info.duration) {
      return (
        <div
          className={classNames(
            "display-flex-flex-direction-row",
            "align-items-center",
            DEFAULT_FONT_SIZE,
            additionalClassName ?? "",
            defaultMarginTop
          )}
        >
          <Clock size={ICON_SIZE} className="mr-2.5" />
          {this.props.info.duration} minutes
        </div>
      );
    }

    return null;
  }

  renderConferencingIcon() {
    if (this.props.info.conferencing === BACKEND_ZOOM) {
      return <img alt="" width="16px" height="16px" src={ZOOM_LOGO_URL} />;
    } else if (this.props.info.conferencing === BACKEND_HANGOUT) {
      return <img alt="" width="16px" height="16px" src={HANGOUT_LOGO_URL} />;
    } else if (this.props.info.conferencing === BACKEND_PHONE) {
      return <img alt="" width="16px" height="16px" src={PHONE_MEETING_ICON} />;
    } else if (this.props.info.conferencing === BACKEND_WHATS_APP) {
      return <img alt="" width="16px" height="16px" src={WHATS_APP_ICON} />;
    } else if (isOutlookConferencingOption(this.props.info.conferencing)) {
      return (
        <img
          alt=""
          width="16px"
          height="16px"
          src={getOutlookConferencingIconURL(this.props.info.conferencing)}
        />
      );
    } else {
      return <Video size={ICON_SIZE} />;
    }
  }

  determineConferencingText() {
    let conferencing;

    if (isOutlookConferencingOption(this.props.info.conferencing)) {
      return convertOutlookConferencingToHumanReadable(
        this.props.info.conferencing
      );
    }

    switch (this.props.info.conferencing) {
      case BACKEND_ZOOM:
        conferencing = "Zoom";
        break;
      case BACKEND_PHONE:
        conferencing = "Phone call";
        break;
      case BACKEND_HANGOUT:
        conferencing = "Google Meet";
        break;
      case BACKEND_WHATS_APP:
        conferencing = "WhatsApp";
        break;
      case BACKEND_CUSTOM_CONFERENCING:
        conferencing = this.props.info.custom_conferencing_name;
        break;
      default:
        break;
    }

    return conferencing;
  }

  onInputChange(inputText = null) {
    const input = inputText || "";

    this._searchLocationTimer && clearTimeout(this._searchLocationTimer);
    this._searchLocationTimer = null;

    this._searchLocationTimer = setTimeout(
      () => this.searchForLocationSuggestion(input),
      500
    );
  }

  createOriginalTimeZones() {
    return CreateTimeZoneList(this.state.selectedTimeZone || guessTimeZone());
  }

  searchForLocationSuggestion(input) {
    if (!input) {
      this.setState({timeZoneOptions: this.createOriginalTimeZones()});
      return;
    }
    if (!this._isMounted) {
      return;
    }

    if (this._locationAutocomplete) {
      this._locationAutocomplete.getPlacePredictions(
        { input: input, types: ["(cities)"] },
        (predictions) => {
          if (!this._isMounted) {
            return;
          }
          const formattedSuggestions =
            this.formatLocationPredictions(predictions);
          const options = _.clone(this.state.timeZoneOptions);

          // {label, value}
          const filteredOptions = this.getSearchOptions({
            input,
            options,
            formattedSuggestions,
          });
          this.setState({ timeZoneOptions: filteredOptions });
        }
      );
    } else if (!this._locationAutocomplete) {
      this.setUpLocationAutoComplete();
    }
  }

  setUpLocationAutoComplete() {
    this._locationAutocomplete = getGoogleMapsAutoComplete();
  }

  formatSingleTimeZoneCountries(matches) {
    return matches.map((country) => {
      const matchingCountry = getMatchingSingleTimeZoneCountry(country);
      const timeZone = countryTimeZones[matchingCountry];
      return {
        value: countryTimeZones[matchingCountry],
        label: `(${createAbbreviationForTimeZone(
          timeZone
        )}) ${matchingCountry}`,
      };
    });
  }

  getSearchOptions({ input, options, formattedSuggestions }) {
    if (isPartOfPopularTimeZone(input)) {
      return options
        .filter((o) => !o.isAddOn)
        .concat(formattedSuggestions)
        .concat(
          this.formatSingleTimeZoneCountries(
            getMatchingSingleTimeZoneCountryResult(input)
          )
        )
        .concat(
          this.formatMultipleTimeZoneCountries(
            getMatchingTimeZoneForMultipleCountryTZs(input)
          )
        );
    }

    return this.formatSingleTimeZoneCountries(
      getMatchingSingleTimeZoneCountryResult(input)
    )
      .concat(
        this.formatMultipleTimeZoneCountries(
          getMatchingTimeZoneForMultipleCountryTZs(input)
        )
      )
      .concat(options.filter((o) => !o.isAddOn))
      .concat(formattedSuggestions);
  }

  formatMultipleTimeZoneCountries(matches) {
    return matches.map((key) => {
      const countryKey = getMatchingMultipleTimeZoneKey(key);
      const timeZone = timeZonesForCountriesWithMultipleTimeZone[countryKey];
      return {
        value: timeZone,
        label: `(${createAbbreviationForTimeZone(timeZone)}) ${countryKey}`
      }
    });
  }

  formatLocationPredictions(predictions) {
    if (isEmptyArray(predictions)) {
      return [];
    }

    return predictions.map((p, index) => {
      return {
        label: `${p.description}`,
        value: p.description,
        isAddOn: true,
      };
    });
  }

  async fetchGeoCode(address) {
    try {
      const response = await getGeoCodeFromAddress(address);
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (
        output?.status === GOOGLE_GEOCODING_RESPONSE_STATUS.OK &&
        output.results?.[0]?.geometry?.location
      ) {
        // if API reports everything was returned successfully
        this.fetchTimeZone(output.results[0].geometry.location);
      } else {
        // do nothing for now
      }
    } catch (error) {
      handleError(error);
    }
  }

  async fetchTimeZone(location) {
    try {
      const response = await getTimeZoneFromLocation(location); // const { lat, lng } = location;
      const output = await response.json();
      if (!this._isMounted) {
        return;
      }

      if (output?.status === GOOGLE_TIME_ZONE_RESPONSE_STATUS.OK && output.timeZoneId) {
        // Add what happens if you're a success here
        const updatedTimeZone = {
          value: output.timeZoneId,
          label: output.timeZoneName,
        };
        const updatedOptions =
          this.state.timeZoneOptions.concat(updatedTimeZone);

        this.setState({
          timeZone: updatedTimeZone,
          timeZoneOptions: updatedOptions,
        }, () => {
          if (this.props.onSelectTimeZone) {
            this.props.onSelectTimeZone(output.timeZoneId);
          }
        });
      } else {
        // do nothing for now
      }
    } catch (error) {
      handleError(error);
    }
  }
}

export default EventInfoWithIcons;
