import classNames from "classnames";
import React, { useEffect, useState } from "react";
import DisabledButton from "../disabledButton";
import GroupVoteSpreadSheetTable from "./groupVoteSpreadSheetTable";
import {
  IsEmailValid,
  isEmptyArray,
  removeDuplicatesFromArray,
} from "../../services/commonUsefulFunctions";
import { getAttendeeEmail, getAttendeeUUID } from "./sharedFunctions";

export default function GroupVoteSpreadSheet({
  children,
  onClickSave,
  bookingLink,
  selectedTimeZone,
  isShowingConfirmPage,
}) {
  // each attendee should look something like this:
  // {
  //   "uuid": "some-uuid-to-create-or-update",
  //   "name": "Name",
  //   "email": "email@email.com",
  //   "slots": [
  //     {
  //       "start/startDate": "start-value",
  //       "end/endDate": "start-value",
  //       "selected": "true/false",
  //       "comment": "some comment",
  //     }
  //   ]
  // }
  const [editedAttendees, setEditedAttendees] = useState([]);
  const [deletedAttendeesUUID, setDeletedAttendeesUUID] = useState([]);

  useEffect(() => {
    const onCloseTab = (e) => {
      // Cancel the event as per the standard.
      if (!e?.preventDefault) {
        return;
      }
      if (isShowingConfirmPage) {
        // do nothing
      } else if (
        deletedAttendeesUUID.length > 0 ||
        editedAttendees.length > 0
      ) {
        e.preventDefault();
        // Chrome requires returnValue to be set.
        e.returnValue = "";
        alert("Are you sure you want to leave? You have unsaved changes.");
      }
    };
    window.addEventListener("beforeunload", onCloseTab);

    return () => {
      window.removeEventListener("beforeunload", onCloseTab);
    };
  }, [deletedAttendeesUUID, editedAttendees, isShowingConfirmPage]);

  const renderSaveButton = () => {
    if (isEmptyArray(editedAttendees) && isEmptyArray(deletedAttendeesUUID)) {
      // has not updated any attendees
      return <DisabledButton label="Save" />;
    }
    return (
      <div
        className={classNames(
          "custom-button-box width-90px margin-right-10 text-white",
          "blue-button"
        )}
        onClick={() => {
          const attendeesWithInvalidEmails = editedAttendees.filter(
            (attendee) => !IsEmailValid(getAttendeeEmail(attendee))
          );
          if (attendeesWithInvalidEmails.length > 0) {
            return;
          }
          const responses = {
            edited_attendees: editedAttendees,
            deleted_attendees: deletedAttendeesUUID,
          };
          onClickSave(responses);
        }}
      >
        {"Save"}
      </div>
    );
  };

  const onDeleteAttendee = (uuid) => {
    setDeletedAttendeesUUID(
      removeDuplicatesFromArray(deletedAttendeesUUID.concat(uuid))
    );
  };

  const onUpdateAttendee = (updatedAttendee) => {
    const updated = editedAttendees
      .filter(
        (attendee) =>
          getAttendeeUUID(attendee) !== getAttendeeUUID(updatedAttendee)
      )
      .concat(updatedAttendee);
    setEditedAttendees(updated);
  };

  const renderScheduler = () => {
    return (
      <div className={classNames("group-vote-spreadsheet-container", "mx-7")}>
        <GroupVoteSpreadSheetTable
          bookingLink={bookingLink}
          selectedTimeZone={selectedTimeZone}
          onDeleteAttendee={onDeleteAttendee}
          onUpdateAttendee={onUpdateAttendee}
        />
      </div>
    );
  };
  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="overflow-y-auto h-full group-vote-spreadsheet-table-scroll-wrapper">
        {children}
        {renderScheduler()}
      </div>
      <div className="w-full flex justify-end my-4 mr-14">
        {renderSaveButton()}
      </div>
    </div>
  );
}
